import loadable from '@loadable/component'
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import config from '../../config'
import { HTMLContent } from '../components/Content'
import OfferTemplate from '../components/OfferTemplate'
import SE0 from '../components/SEO'
import Layout from '../components/Layout'
import ReactStars from "react-rating-stars-component"
import { DatabaseRef } from '../firebase'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import { TrimStr } from '../components/Trim'


const Share = loadable(() => import('../components/Share'))
const ModalCall = loadable(() => import('../components/ModalCall'))
const NewsCall = loadable(() => import('../components/NewsCall'))




const OfferPage = (props) => {
  const { data: { markdownRemark: { html, id, fields: { slug }, frontmatter: { title, meta_title, meta_description, cover, date, tags, desc, subtitle, icon, keywords } } } } = props


  let productJSONLD =
  {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: title,
    image: "https://artopen.co"+cover.publicURL,
    description: desc,
    brand: "Art Open",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "0",
      bestRating: "5",
      worstRating: "1",
      ratingCount: "0"
    }
  }


  var trimmedtitle = TrimStr(title);


  var anchorName =  title.replace(/\s/g, '');
  //anchorName = anchorName.slice(0,1);
  var anchorNameTrimmed = '/#' + anchorName;

  for (let i=1;i<keywords.length;i++){
    if (keywords[i].charAt(0)!==' '){
    keywords[i] = ' ' + keywords[i];
    }
    //console.log(keywords[i]);
  }
  //console.log(String(keywords));

  let BrewstrRef = DatabaseRef.child('ratings/offer/'+trimmedtitle);



function readRatings(){
  var element;
  BrewstrRef.once("value").then(function(snapshot){
      var loginInfo=snapshot.val();
        //console.log(loginInfo);

      if((loginInfo !== null)&&(loginInfo !== undefined)){
        const entries = Object.entries(loginInfo);
        //console.log(entries.length);

        document.getElementById('numof').innerText = 'ratings: ' + entries.length;

        productJSONLD.aggregateRating.ratingCount = String(entries.length);
        console.log(productJSONLD.aggregateRating.ratingCount)

        //let ips = [];
        let rats = [];

        for (let i=0;i<entries.length;i++){

          let propertyValues = Object.values(entries[i]);

          //console.log(propertyValues[1].ip);
          //ips.push(propertyValues[1].ip);
          rats.push(Number(propertyValues[1].rating));
        }

        //console.log(ips);
        //console.log(rats);
        let total = 0;
        for (let i=0;i<rats.length;i++){
          total += rats[i];
        }
        let avg = total/rats.length;
        //console.log(avg);
        document.getElementById('avgrat').innerText = 'average rating: ' + avg.toFixed(1);
        productJSONLD.aggregateRating.ratingValue = String(avg.toFixed(1));
        console.log(productJSONLD.aggregateRating.ratingValue);

         element = (<Helmet>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
          {JSON.stringify(productJSONLD)}
          </script>
        </Helmet>);
        ReactDOM.render(

            element,
            document.getElementById('json')

        );

      } else {
        document.getElementById('numof').innerText = 'ratings: 0';
        document.getElementById('avgrat').innerText = 'average rating: 0';

        productJSONLD.aggregateRating.ratingValue = '0';
        productJSONLD.aggregateRating.ratingCount = '0';


      }

    });

  }

  readRatings();










  function json(url) {
    return fetch(url).then(res => res.json());
  }

  const testFire = (newRating) => {

    json('https://api.ipify.org?format=json').then(data => {
      BrewstrRef.once("value").then(function(snapshot){
        var loginInfo=snapshot.val();
        if ((loginInfo !== null) && (loginInfo !== undefined)) {
          const entries = Object.entries(loginInfo);

          let ips = [];
          for (let i=0; i<entries.length; i++) {
            let propertyValues = Object.values(entries[i]);
            ips.push(propertyValues[1].ip);
          }

          if (ips.includes(data.ip)) {
            document.getElementById('rated').style.display = 'block';
            document.getElementById('rated').innerText = 'It seems that you have already evaluated the article.';
            setTimeout(function() {
              if(document.getElementById('rated') !== null) {
                document.getElementById('rated').style.display = 'none';
              }
            }, 1500)
          } else {
            BrewstrRef.push().set({rating:newRating, ip: data.ip});
            readRatings();
          }
        } else {
          BrewstrRef.push().set({rating:newRating, ip: data.ip});
          readRatings();
        }
      })
    })
  }







  return (
    <Layout>
      <Helmet>
      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Offer",
          "item": "https://artopen.co/offer/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": title,
        }]
      })}
      </script>
      <link rel="canonical" href={'https://artopen.co'+slug} />
       </Helmet>
      <div id="json"></div>
      <section className='full'>
        <SE0
          title={title}
          meta_title={meta_title}
          meta_desc={meta_description}
          keywords ={keywords}
          cover={cover.publicURL}
          slug={slug}
          date={date}
          siteTitleAlt={config.siteTitleAlt}
          userName={config.userName}
          siteTitle={config.siteTitle}
          siteUrl={config.siteUrl}
          siteFBAppID={config.siteFBAppID}
          userTwitter={config.userTwitter}
          pathPrefix={config.pathPrefix}
        />
        <div>
        <nav className="breadcrumbs-nav" style={{zIndex:'10',top:'45px',left:'10px',color:'white'}}>
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/offer/">Offer</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>{title}</b></span>
        </nav>
              <OfferTemplate
                content={html}
                contentComponent={HTMLContent}
                cover={cover.publicURL}
                meta_title={meta_title}
                meta_desc={meta_description}
                tags={tags}
                title={title}
                date={date}
                icon={icon.publicURL}
                desc={desc}
                subtitle={subtitle}
              />

          <div>
            <div className='is-10 is-offset-1'>
            <br />
              <p className='subtitle' style={{marginLeft:'5%',maxWidth:'90%',textAlign:'center'}}> <b>Evaluate our work:</b> </p>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <ReactStars
                  count={5}
                  onChange={testFire}
                  size={34}
                  activeColor="#23C495"
                />
              </div>
              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
              <sub id='numof'></sub>&nbsp;&nbsp;&nbsp;
              <sub id='avgrat'></sub>
              </div>
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'45px'}}>
              <p style={{display:'none'}} id='rated'></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsCall />
      <div style={{textAlign:'center',paddingTop:'50px'}}>
      <br />

      <h3 style={{fontSize:'18px'}}> Fill in our <Link className="inline-link" to="/quote-art-open/"><b>form</b></Link></h3>
      <br />
      <h4 style={{fontSize:'14px',marginLeft:'20%',marginRight:'20%'}}>Answer the questions and get free service pricing. In our offer you will find many services, such as <Link className="inline-link" to='/offer/graphic-design/'>graphic design</Link>, personalized <Link className="inline-link" to='/offer/book-calendars/'>book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'>company calendars</Link>, <Link className="inline-link" to='/offer/ecology/'>ecological calendars</Link> <Link className="inline-link" to='/offer/gadgets/'>advertising gadgets</Link>, <Link className="inline-link" to='/offer/printouts/'>printouts</Link>, <Link className="inline-link" to='/offer/filming/'>advertising movies</Link> and <Link className="inline-link" to='/offer/photography/'>advertising photos</Link> as well as <Link className="inline-link" to='/offer/web-pages/'>website programming, including e-commerce, applications, </Link> or <Link className="inline-link" to='/offer/vr/'>AR and VR systems.</Link></h4>
      <br />
      <br />
      <Link className='button-green' to={'/quote-art-open'+anchorNameTrimmed}> get a quote &nbsp;&nbsp;
      <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="ecology" src='/img/leaf.svg'/>
       </Link>
      <br />
      <br />
      <br />
      <br />
      </div>
    </Layout>
  )
}

OfferPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}



export default OfferPage

export const pageQuery = graphql`
  query OfferByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
            slug
          }
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        icon {
            publicURL
        }
        cover {
            publicURL
        }
        meta_title
        meta_description
        tags
        desc
        subtitle
        keywords
      }
    }
  }
`
