import loadable from '@loadable/component'
import React from 'react'
import Content from '../Content'
import OfferNav from '../OfferNav'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import ProgressiveImageContainer from '../ProgressiveImageContainer'

const ModalCall = loadable(() => import('../ModalCall'))

const OfferTemplate = (props) => {
  const { content, contentComponent, cover, tags, title, desc, subtitle, icon } = props
  const PostContent = contentComponent || Content



  return (

    <>
    <div style={{backgroundImage:'url('+cover+')',backgroundSize:'cover',backgroundAttachment:'fixed'}}
      className="offimg"
    >
    <Link to='#content'>
    <div className='ofintro'>

      <div style={{textAlign:'center'}}>
        <img src={icon} width='50px' alt={title} title={title}
        style={{filter:'brightness(0) invert(1)'}}
        />

      <h1  className='title is-size-2 has-text-weight-bold is-bold-light'
      id="oftitle"
      style={{color:'white'}}
      >
        {title}
      </h1>
      </div>
      <br />
      <h2 className='subtitle'
      id='subtitle'
      style={{color:'white'}}
      >
        {desc}
      </h2>

      <div style={{textAlign:'center',marginBottom:'-20px'}}>

        <img src="/img/angle-down.svg" width='20px' alt={title} title={title}
        style={{filter:'brightness(0) invert(1)'}}
        />

      </div>


    </div>
    </Link>
    </div>

      <br></br>
      <div id="content" style={{paddingTop:'100px',paddingBottom:'50px'}}>
        <h2 className='title is-size-3 has-text-weight-bold is-bold-light oftitle2 stays-green'
        style={{marginLeft:'5%',marginRight:'5%',maxWidth:'70%'}}
        >
        {subtitle}
        </h2>
        <br />
        <br />
        <PostContent content={content} />

        <br />
        <br />
        <ModalCall />


        <br />
        <br />
        <br />

        <div className='bottomUi' style={{textAlign:'center',marginBottom:'30px',marginLeft:'20px'}}>
        <Link className='button-green' style={{fontSize:'15px'}} to='/portfolio/'> See our realisations&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/blog/'> Read the blog&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link className='button-green' style={{fontSize:'15px'}} to='/contact/'> Contact us&nbsp;&nbsp; <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        </div>

        <div style={{marginTop: `2rem`, marginLeft:'5%',maxWidth:'90%'}}>
          <p><b>Tags:</b></p>
          <br /><br />
          <ul className='taglist columns' style={{marginLeft:'10px',display:'flex',flexWrap:'wrap',lineHeight:'3.5'}}>
            {(tags && tags.length)
              ? tags.map(tag => (
                <li className='tags1' key={tag + `tag`}>
                  <Link className="button-green" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link> &nbsp; &nbsp;
                </li>
              ))
              : null}
          </ul>
        </div>

      </div>
      <OfferNav />
    </>

  )
}



export default OfferTemplate
